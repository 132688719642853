import { EventCode } from "@coderone/library";
import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRedeemTicket } from "../../../../hooks/api/ticket/useRedeemTicket";
import { useTicket } from "../../../../hooks/api/ticket/useTicket";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { Spinner } from "../../../Spinner/Spinner";
import { TextInput } from "../../../TextInput/TextInput";
import { Root } from "./RedeemTicket.styles";

export const RedeemTicketContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const [redemptionCode, setRedemptionCode] = useState("");
    const onTeamNameChanged = useCallback(
        (_: unknown, value: string | undefined) => {
            const newValue = value === undefined ? "" : value;
            setRedemptionCode(newValue);
        },
        [setRedemptionCode]
    );

    const redeemTicket = useRedeemTicket(EventCode.BomberlandV4, redemptionCode);

    const onSubmitRedemptionCode = useCallback(() => {
        redeemTicket.mutate();
    }, [redemptionCode, redeemTicket.mutate]);
    const bomberlandTicket = useTicket(EventCode.BomberlandV4);

    const isSubmitDisabled = React.useMemo(() => redemptionCode.length <= 0, [redemptionCode]);
    if (bomberlandTicket.status === "loading") {
        return <Spinner />;
    }
    if (bomberlandTicket.data !== null) {
        return <Root>Ticket has been successfully redeemed!</Root>;
    }
    return (
        <Root>
            <ContentCard>
                <TextInput value={redemptionCode} onChange={onTeamNameChanged} placeholder={t("redeemTicketPage.redemptionCode")} />
                <PrimaryButton aria-label={t("redeemTicketPage.redeem")} onClick={onSubmitRedemptionCode} disabled={isSubmitDisabled}>
                    {t("redeemTicketPage.redeem")}
                </PrimaryButton>
            </ContentCard>
        </Root>
    );
};
