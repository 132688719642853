import * as React from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { WithAuth } from "../../../Auth/WithAuth";
import { SEO } from "../../../SEO/SEO";
import { RedeemTicketContent } from "./RedeemTicketContent";

const RedeemTicket: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("redeemTicketPage.title");
    const description = t("redeemTicketPage.description");
    return (
        <WithAuth>
            <SEO description={description} title={title} />
            <AuthenticatedFrame title={title}>
                <RedeemTicketContent />
            </AuthenticatedFrame>
        </WithAuth>
    );
};

export default RedeemTicket;
