import { useTranslation } from "react-i18next";
import { AlertsContext, AlertType, IAlert } from "./../../../components/Alerts/AlertsContext";
import { EventCode } from "@coderone/library";
import axios from "axios";
import { useContext } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { Constants } from "../../../utilities/Constants";
import { AuthContext } from "../../../components/Auth/AuthContext";

const getMutationQuery = (token: string | null, code: string, eventCode: EventCode) => {
    const postRequest = async (): Promise<{}> => {
        const config = getAxiosRequestConfig(token);
        const { data } = await axios.post(`${Constants.ApiRoot}/ticket/${eventCode}/redeem`, { code }, config);
        return data;
    };
    return postRequest;
};

export const useRedeemTicket = (eventCode: EventCode, code: string) => {
    const [t] = useTranslation();
    const { pushAlert } = useContext(AlertsContext);
    const { token } = useContext(AuthContext);
    const queryClient = useQueryClient();

    const mutation = useMutation(getMutationQuery(token, code, eventCode), {
        onSuccess: (_data) => {
            queryClient.invalidateQueries(["ticket"]);
        },
        onError: (_error, _variables, _context) => {
            const alert: IAlert = {
                type: AlertType.Error,
                message: t("redeemTicketPage.redemptionCodeNotFound"),
                timeout: 3000,
            };
            pushAlert?.(alert);
        },
    });

    return mutation;
};
